/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    ul: "ul",
    li: "li",
    pre: "pre",
    code: "code",
    strong: "strong",
    blockquote: "blockquote",
    h3: "h3",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "This article goes over solutions for managing multiple versions of Node.js on macOS. We'll start with a solution that I felt was straightforward and easily manageable. This is a living article and other solutions may be added over time. This solution was pulled from ", React.createElement(_components.a, {
    href: "https://notiz.dev/blog/how-to-manage-multiple-node-versions-on-mac"
  }, "How to manage multiple Node.js versions on macOS X - Notiz.dev"), " and this article is meant as a public personal reference."), "\n", React.createElement(_components.h2, null, "Assumptions"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "HomeBrew is installed"), "\n", React.createElement(_components.li, null, "general understanding of bash/zsh profiles"), "\n"), "\n", React.createElement(_components.h2, null, "Install Desired Node Versions"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "brew install node@<version>\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "brew install node@16\n")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "The default ", React.createElement(_components.code, null, "brew install node"), " installed the latest version"), "\n"), "\n", React.createElement(_components.h3, null, "Useful CLI commands to check work"), "\n", React.createElement(_components.p, null, "Check node version"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "node --version\n")), "\n", React.createElement(_components.p, null, "Check node install location"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "which node\n")), "\n", React.createElement(_components.h3, null, "Use Alsias to switch Node versions"), "\n", React.createElement(_components.p, null, "Add and alias to ", React.createElement(_components.code, null, ".zshrc"), " for each Node version installed."), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Copy the following to .zshrc")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "alias node16='export PATH=\"/usr/local/opt/node@16/bin:$PATH\"'\nalias node14='export PATH=\"/usr/local/opt/node@14/bin:$PATH\"'\n")), "\n", React.createElement(_components.p, null, "or from terminal"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "echo -n alias node13='export PATH=\"/usr/local/opt/node@16/bin:$PATH\"' >> ~/.zshrc\necho -n alias node13='export PATH=\"/usr/local/opt/node@14/bin:$PATH\"' >> ~/.zshrc\n")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Refernece alias in CLI to switch between node versions"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, " node16\n node -v # v16.11.0\n")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "📔 This solution only changes the Node version in the terminal instance"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
